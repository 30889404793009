import React from "react"
import Seo from "@components/SEO"
import _ from "lodash"
import { markdownify } from "../utils"

export default function PageSection(props) {
  let section = _.get(props, "section", null)
  return (
    <section
      className="content__row content__row--full-width page__section"
      data-id={_.get(section, "section_id", null)}
    >
      <Seo title={_.get(section, "title", null)} />
      <div className="max-w-6xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl text-base-content">
          {_.get(section, "title", null)}
        </h2>
        <div className="relative mt-12  text-base-content">
          {markdownify(_.get(section, "content", null))}
        </div>
      </div>
    </section>
  )
}
