import React from "react"
import _ from "lodash"
import { graphql } from "gatsby"
import Layout from "@components/Layout"
import components from "../components/index"
import { ThemeProvider } from "../components/themeContext"
import HeaderPromotion from "../components/HeaderPromotion"
import Header from "@components/Header"
import Footer from "@components/Footer"
import LoadChat from "@components/Chat"

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query ($url: String) {
    sitePage(path: { eq: $url }) {
      id
    }
  }
`

export default class Main extends React.Component {
  render() {
    return (
      <ThemeProvider>
        <LoadChat />
        <Layout {...this.props} site={this.props.pageContext.site}>
          <div class="z-50">
            <HeaderPromotion />
          </div>
          <Header />
          {/* <Helmet>
          <title>{`${title} | ${config.siteTitle}`}</title>
        </Helmet> */}
          {/* <SEO postPath={slug} postNode={postNode} postSEO /> */}
          <main className="main">
            {_.map(
              _.get(this.props, "pageContext.frontmatter.sections", null),
              (section, section_idx) => {
                let component = _.upperFirst(
                  _.camelCase(_.get(section, "type", null))
                )
                let Component = components[component]
                return (
                  <Component
                    key={section_idx}
                    {...this.props}
                    section={section}
                    page={this.props.pageContext}
                    site={this.props.pageContext.site}
                  />
                )
              }
            )}
          </main>
          <Footer />
        </Layout>
      </ThemeProvider>
    )
  }
}
